import React, { useState } from "react";
import "./Stocks.css";
import FormComponent from "./FormComponent";
import Input from "./Input/Input";
import glass from "../../Assets/imgs/glass.svg";
import Filter from "./Filter/Filter"

let arr1 = ["Q1 2023", "Q2 2023", "Q3 2023", "Q4 2023"];

let arr2 = ["JSWSTEEL", "OLA", "NYKAA", "TESLA"];

const Stocks = () => {
  const [child, setchild] = useState([]);

  const sendData = (data) => {
    setchild(data);
  };

  return (
    <div className="wrapper">
      <div className="heading">
        <h1>Welcome!</h1>
        <p>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aut,
          dolorum.
        </p>
      </div>
      <div className="search">
        <p>Search within :</p>
        <FormComponent title="Any Time" arr={arr1} sendData={sendData} />
        <FormComponent
          title="Any Stock"
          arr={arr2}
          style={{ width: "200px" }}
          sendData={sendData}
        />
      </div>
      <div className="row1_input">
        <img src={glass} alt="" />
        <Input placeholder="Enter any question here" />
      </div>
      <div className="selected">
        {child.length > 0 &&
          child.map((item, index) => {
            return (
              <div key={index}>
                <Filter label={item}  />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Stocks;
