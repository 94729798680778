import "./AddStudentUpdateTutor.scss";
import profile from "./profile.svg";

const AddStudentUpdateTutor = () => {
  return (
    <aside>
      <div className="sideContainer">
        <div className="profileImageContainer">
          <img className="profileImg" src={profile} alt="" />
          <p>Namaste, John P.</p>
        </div>
        <div className="tutorName">
          <h2>Basic Instruction</h2>
        </div>
        <div className="tutorInfoList">
          <ul>
            <li>Select the best suitable student to mentor according to academic Details of the student. </li>
            <li>Match and Success Rate shows you chances to get the student Onboard.</li>
            <li>You send an Proposal by mentioning all the details like Timings, Fee per hour, etc.</li>
            <li>Make sure that provided information is up to latest updates.</li>
          </ul>
        </div>
      </div>
    </aside>
  );
};

export default AddStudentUpdateTutor;
