import "./App.css";
import { Route,Routes } from "react-router-dom";
import Home from "./components/Home/Home";
import Proplan from './components/New/Proplan'
import AddStudentUpdateTutor from "./components/AddStudentUpdateTutor/AddStudentUpdateTutor"
import Stocks from "./components/Stocks/Stocks";
import First from "./components/Chronicles/First";
import Second from "./components/Chronicles/Second";

function App() {
  return (
   <>
   <Routes>
    {/* <Route path="/" element={<Home/>}></Route>   */}
    <Route path="/dashboard" element={<AddStudentUpdateTutor/>}></Route>
    <Route path="/pro" element={<Proplan/>}></Route>
    <Route path="/" element={<Stocks/>}></Route>
    <Route path="/first" element={<First/>}></Route>
    <Route path="/second" element={<Second/>}></Route>
   </Routes>    
   </>
  );
}

export default App;
