import React, { useEffect } from "react";
import { useState } from "react";
import "./FormComponent.css";


const FormComponent = ({ title, arr, sendData }) => {
    
  const [isexpanded, setIsExpanded] = useState(false);
  const [selectedStocks, setSelectedStocks] = useState([]);
    useEffect(() => {
        sendData(selectedStocks);
    },[selectedStocks])

  const changeHandler = (event) => {
    const item = event.target.value;
    if (selectedStocks.includes(item)) {
      setSelectedStocks(selectedStocks.filter((stock) => stock !== item));
    } else {
      setSelectedStocks([...selectedStocks, item]);
    }
  };

  const deselectHandler = (item) => {
    setSelectedStocks(selectedStocks.filter((stock) => stock !== item));
  }

  

  return (
    <React.Fragment>
     
      <form>
        <div className="multiselect">
          <div
            className="selectBox"
            onClick={() => {
              setIsExpanded(!isexpanded);
            }}
          >
            <select>
              <option>{title}</option>
            </select>
            <div className="overSelect"></div>
          </div>
          {isexpanded && (
            <div id="checkboxes">
              {arr.map((item, index) => {
                return (
                  <label htmlFor={index} key={index}>
                    <input
                      type="checkbox"
                      id={index}
                      value={item}
                      onChange={(e) => {
                        changeHandler(e);
                      }}
                    />
                    {item}
                  </label>
                );
              })}
            </div>
          )}
        </div>
      </form>
    </React.Fragment>
  );
};

export default FormComponent;
