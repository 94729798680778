import React from 'react'
import "./Input.css"

const Input = ({placeholder,padding}) => {
  return (
    <div className="input_bg">
        <input type="text" placeholder={placeholder} padding={padding}/>
    </div>
  )
}

export default Input