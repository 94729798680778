import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { SplitText } from "gsap-trial/SplitText"

import './First.css';

const First = () => {
  const paragraphRef = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(SplitText);

    const paragraph = paragraphRef.current;
    const splitText = new SplitText(paragraph, { type: 'words' });

    const words = splitText.words;

    gsap.from(words, {
      opacity: 0,
      y: 20,
      duration: 0.8,
      stagger: 0.1,
      scrollTrigger: {
        trigger: paragraph,
        start: 'top 80%',
        end: 'bottom 20%',
        scrub: true,
      },
      letterSpacing:10
    });
  }, []);

  return (
    <div className="mainContainer">
      <div className="subContainer">
        <p className="textBox" ref={paragraphRef}>
          In Chronicle everything is made with Blocks that come with pixel perfect design, interactivity and motion out of the box. Instead of designing from scratch, simply choose the right one from our library of blocks and see the magic unfold.
        </p>
      </div>
    </div>
  );
};

export default First;