import "./Filter.css"
import cross from "../../../Assets/imgs/cross.svg"

const Filter = ({label,deselectHandler}) => {
  return (
    <div className='filter_bg'>
        <img src={cross} alt="" onClick={deselectHandler}  />
        <p>{label}</p>
    </div>
  )
}

export default Filter